<template>
    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
        <b-modal id="national-legal-type-modal" ref="upsertModal" hide-footer @hidden="onUpsertModalHidden">
            <template #modal-header>
                <div class="w-100 h-100 d-flex justify-content-between align-items-center">
                    <template v-if="isEdit">
                        <h4 class="mb-0">{{ $t("Update legal type") }}</h4>
                    </template>
                    <template v-else>
                        <h4 class="mb-0">{{ $t("Create legal type") }}</h4>
                    </template>
                    <div class="ml-5 d-flex align-items-center">
                        <b-button variant="success" class="mr-2"
                            :class="{ 'spinner spinner-light spinner-right': isSaving }" @click="handleSubmit(submit)">
                            {{ $t("Submit") }}
                        </b-button>
                        <b-button @click="closeUpsertModal">
                            {{ $t("Cancel") }}
                        </b-button>
                    </div>
                </div>
            </template>
            <nation-legal-type-form :existing-form="existingForm" :nation="nation"
                @input="onLegalTypeFormInput"></nation-legal-type-form>
        </b-modal>
    </validation-observer>
</template>
<script>
import { backendErrorSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";
import NationLegalTypService from '@/core/services/nation/nation-legal-type.service';
import NationLegalTypeForm from '@/view/components/forms/nations/NationLegalTypeForm.vue'
import { getValidationErrors } from "@/core/helpers";
import eventBus from "@/core/services/event/eventBus.js"

export default {
    components: {
        NationLegalTypeForm
    },

    props: {
        existingForm: {
            type: Object,
            default: null,
        },
        nation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            item: null,
            isSaving: false,
            apiErrors: null,
        }
    },
    computed: {
        isEdit() {
            return Object.keys(this.existingForm).length > 0
        }
    },
    watch: {
        apiErrors: {
            deep: true,
            handler(val) {
                const formObs = this.$refs.validationObserver;
                const newErrors = getValidationErrors(val, this.item);
                formObs.setErrors({ ...newErrors });
            },
        },
    },

    methods: {
        closeUpsertModal() {
            this.$bvModal.hide('national-legal-type-modal');
        },

        onUpsertModalHidden() {
            this.item = null;
            this.isSaving = false;
        },
        async submit() {
            this.isSaving = true;
            try {
                if (this.isEdit) {
                    const form = { ...this.item }
                    await NationLegalTypService.update(form, form.id)
                } else {
                    const create_form = { ...this.item, id: this.id }
                    await NationLegalTypService.create(create_form)
                }
                this.closeUpsertModal();

                let title = this.$t("Contract level created")
                if (this.isEdit) {
                    title = this.$t("Contract level updated")
                }
                Swal.fire(this.$t("Success"), title, "success");
                eventBus.$emit('nation-legal-types-refresh');
            } catch (err) {
                if (err?.response?.status === 400) {
                    this.apiErrors = err.response.data;
                } else {
                    console.log(err)
                    let message = err?.response?.data?.detail ?? this.$t("Failed to create the legal type. Please try again.")
                    if (this.isEdit) {
                        console.log(err);
                        message = err?.response?.data?.detail ?? this.$t("Failed to update the legal type. Please try again.")
                    }
                    backendErrorSwal(message, err);
                }
            }
            finally {
                this.isSaving = false;
            }
        },
        onLegalTypeFormInput(c) {
            this.item = c;
        },
    }
};

</script>