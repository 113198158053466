var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Name'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Name'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Description')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-text-area', {
          attrs: {
            "label": _vm.$t('Description'),
            "error-messages": errors
          },
          model: {
            value: _vm.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "description", $$v);
            },
            expression: "form.description"
          }
        })];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }