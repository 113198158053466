<template>
    <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder">
                    {{ $t("Nation legal types") }}
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">
                    {{ $t("Manage nation's legal types") }}
                </span>
            </div>
            <div class="card-toolbar">
                <b-button class="text-right font-weight-bolder my-2" variant="light-primary"
                    @click="createItemClick('national-legal-type-modal')">
                    <span class="svg-icon menu-icon">
                        <inline-svg src="/media/svg/icons/Files/File.svg" />
                    </span>
                    <span>{{ $t("Add LegalType") }}</span>
                </b-button>
            </div>
        </div>
        <div class="card-body detail">
            <div class="row">
                <div class="col-6">
                    <div class="w-100">
                        <h5 class="font-weight-bolder mb-5 border-bottom pb-5">{{ $t("Legal types list") }}</h5>
                    </div>
                    <!--begin::Item-->
                    <div v-for="(lt, i) in legalTypes" :key="i"
                        class="d-flex flex-wrap border-bottom align-items-center p-4">

                        <!--begin::Title-->
                        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <span class="text-dark font-weight-bold mb-1 font-size-lg"> {{ lt.name }}</span>
                        </div>
                        <!--end::Title-->
                        <button class="btn btn-icon btn-light mr-4" @click="editItemClick('national-legal-type-modal', lt)">
                            <span class="svg-icon svg-icon-md svg-icon-primary" :title="$t('Edit')">
                                <inline-svg src="/media/svg/icons/General/Edit.svg" />
                            </span>

                        </button>

                        <!--begin::Btn-->
                        <button class="btn btn-icon btn-light mr-4" @click="deleteLegalType(lt.id)">
                            <span class="svg-icon svg-icon-md svg-icon-primary" :title="$t('Remove')">
                                <inline-svg src="/media/svg/icons/General/Trash.svg" />
                            </span>

                        </button>
                        <!--end::Btn-->
                        <button class="btn btn-icon btn-light" @click="selectedLegalType = lt">
                            <span class="svg-icon svg-icon-md svg-icon-primary" :title="$t('Detail')">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-right.svg" />
                            </span>

                        </button>
                    </div>
                    <!--end::Item-->

                </div>
                <div class="col-6">
                    <div class="w-100">
                        <h5 class="font-weight-bolder mb-5 border-bottom pb-5">{{ $t("Details") }}</h5>
                    </div>
                    <div v-if="selectedLegalType" class="p-2">
                        <div class="border-bottom mb-2 pb-2 pt-3">
                            <span class="font-weight-bold font-size-md mt-1 mr-4"> Name: </span>
                            <span class="font-size-md mt-1"> {{ selectedLegalType.name }}</span>
                        </div>
                        <div class="border-bottom mb-2 pb-2">
                            <span class="font-weight-bold font-size-md mt-1 mr-4"> Description: </span>
                            <span class="font-size-md mt-1"> {{ selectedLegalType.description }}</span>
                        </div>
                    </div>
                </div>
                <NationLegalTypeModal :existing-form="editForm" :nation="nation" />
            </div>
        </div>
    </div>
</template>

<script>
import NationLegalTypeService from '@/core/services/nation/nation-legal-type.service';
import NationLegalTypeModal from '@/view/components/modals/nations/NationLegalTypeModal.vue';
import eventBus from "@/core/services/event/eventBus.js"


import { deleteSwal, backendErrorSwal } from "@/core/helpers/swal";

export default {
    components: {
        NationLegalTypeModal
    },
    props: {
        nation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            editForm: {},
            legalTypes: [],
            selectedLegalType: null,
            isLoadingAction: false,
            edit: false,
            add: false,
        }
    },
    watch: {
        add() {
            console.log(this.add)
        },
        edit() {
            console.log(this.edit)
        }
    },
    mounted() {
        this.loadLegalTypes();
        eventBus.$on('nation-legal-types-refresh', () => {
            this.loadLegalTypes();
        })
    },
    methods: {
        async editItemClick(id, item) {
            this.selectedLegalType = item;
            this.editForm = { ...this.selectedLegalType };
            this.$bvModal.show(id);
        },
        loadLegalTypes() {
            NationLegalTypeService.getLegalTypes(this.nation.iso).then(res => {
                this.legalTypes.splice(0);
                this.legalTypes = res.results;
                if (this.selectedLegalType)
                    this.selectedLegalType = res.results.find(el => el.id == this.selectedLegalType.id)
            })
        },
        async deleteLegalType(id) {
            this.isLoadingAction = true
            const { isConfirmed } = await deleteSwal({ text: this.$t("Are you sure?") })

            if (isConfirmed) {
                NationLegalTypeService.delete(id).then(() => {
                    this.legalTypes = this.legalTypes.filter(el => el.id == id)
                    this.selectedLegalType = null
                    this.isLoadingAction = false
                    this.loadLegalTypes();
                }).catch(err => {
                    console.log(err);
                    backendErrorSwal(err, this.$t("Error: object used somewhere else."))
                    this.isLoadingAction = false
                })
            }
        },
        createItemClick(id) {
            this.editForm = {};
            this.$bvModal.show(id);
        },
    }
};
</script>
