var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Nation legal types")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("Manage nation's legal types")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('b-button', {
    staticClass: "text-right font-weight-bolder my-2",
    attrs: {
      "variant": "light-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.createItemClick('national-legal-type-modal');
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/File.svg"
    }
  })], 1), _c('span', [_vm._v(_vm._s(_vm.$t("Add LegalType")))])])], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('h5', {
    staticClass: "font-weight-bolder mb-5 border-bottom pb-5"
  }, [_vm._v(_vm._s(_vm.$t("Legal types list")))])]), _vm._l(_vm.legalTypes, function (lt, i) {
    return _c('div', {
      key: i,
      staticClass: "d-flex flex-wrap border-bottom align-items-center p-4"
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2"
    }, [_c('span', {
      staticClass: "text-dark font-weight-bold mb-1 font-size-lg"
    }, [_vm._v(" " + _vm._s(lt.name))])]), _c('button', {
      staticClass: "btn btn-icon btn-light mr-4",
      on: {
        "click": function click($event) {
          return _vm.editItemClick('national-legal-type-modal', lt);
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-md svg-icon-primary",
      attrs: {
        "title": _vm.$t('Edit')
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/General/Edit.svg"
      }
    })], 1)]), _c('button', {
      staticClass: "btn btn-icon btn-light mr-4",
      on: {
        "click": function click($event) {
          return _vm.deleteLegalType(lt.id);
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-md svg-icon-primary",
      attrs: {
        "title": _vm.$t('Remove')
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/General/Trash.svg"
      }
    })], 1)]), _c('button', {
      staticClass: "btn btn-icon btn-light",
      on: {
        "click": function click($event) {
          _vm.selectedLegalType = lt;
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-md svg-icon-primary",
      attrs: {
        "title": _vm.$t('Detail')
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/Navigation/Arrow-right.svg"
      }
    })], 1)])]);
  })], 2), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('h5', {
    staticClass: "font-weight-bolder mb-5 border-bottom pb-5"
  }, [_vm._v(_vm._s(_vm.$t("Details")))])]), _vm.selectedLegalType ? _c('div', {
    staticClass: "p-2"
  }, [_c('div', {
    staticClass: "border-bottom mb-2 pb-2 pt-3"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" Name: ")]), _c('span', {
    staticClass: "font-size-md mt-1"
  }, [_vm._v(" " + _vm._s(_vm.selectedLegalType.name))])]), _c('div', {
    staticClass: "border-bottom mb-2 pb-2"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-md mt-1 mr-4"
  }, [_vm._v(" Description: ")]), _c('span', {
    staticClass: "font-size-md mt-1"
  }, [_vm._v(" " + _vm._s(_vm.selectedLegalType.description))])])]) : _vm._e()]), _c('NationLegalTypeModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "nation": _vm.nation
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }