<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <validation-provider v-slot="{ errors }" :name="$t('Name')" rules="required">
          <euro-input v-model="form.name" :label="`${$t('Name')}*`" :error-messages="errors"></euro-input>
        </validation-provider>
      </b-col>

      <b-col cols="12">
        <validation-provider v-slot="{ errors }" :name="$t('Description')">
          <euro-text-area v-model="form.description" :label="$t('Description')" :error-messages="errors"></euro-text-area>
        </validation-provider>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

export default {
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    nation: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        country: "",
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },

  },
  async mounted() {
    this.form.country = this.nation.iso;
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
  },
};
</script>