import api from "../api.service";



class NationsGroupsService {
  get PATH() {
    return "nations-legaltypes";
  }

  async getLegalTypes(country) {
    const res = await api.get(`${this.PATH}/`, {
      params: {
        country: country
      }
    });
    return res.data;
  }

  async create(item) {
    const res = await api.post(`${this.PATH}/`, item);
    return res.data;
  }

  async update(body, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, body);
    return res.data;
  }

  async delete(id) {
    return await api.delete(`${this.PATH}/` + id)
  }
}



export default new NationsGroupsService();